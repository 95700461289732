<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách ví</h6>
        </template>

        <b-row>
          <b-col>
            <b-form-group label="Số điện thoại">
              <b-form-input
                  v-model="filter.phone"
                  placeholder="Số điện thoại"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Trạng thái">
              <b-form-select :options="status" v-model="filter.status"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-button class="mr-2" variant="outline-info" @click="search">Tìm kiếm</b-button>
          </b-col>
        </b-row>

        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>

          <template #cell(status)="data">
            {{ convertStatus(data.item.status) }}
          </template>

          <template #cell(updated_at)="data">
            {{ formatDate(data.item.updated_at) }}
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      id: this.$route.query.id,
      items: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      query: null,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        phone: null,
        status: null,
      },
      fields: [
        { id: "Id" },
        { user_id: "User ID" },
        { phone: "Số điện thoại" },
        { status: "Trạng thái" },
        { created_at: "Ngày tạo" },
        { updated_at: "Ngày cập nhật" },
      ],
      status: [
        { text: "Tất cả", value: "" },
        { text: "Đang chờ", value: "PENDING" },
        { text: "Đã duyệt", value: "APPROVED" },
        { text: "Từ chối", value: "REJECTED" },
        { text: "Đang xử lý", value: "PROCESSING" },
        { text: "Đã xử lý", value: "SUCCESS" },
        { text: "Lỗi", value: "FAILED" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách hành động", route: "/tools/segment/action" },
    ]);
  },
  methods: {
    convert(params) {
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      return params;
    },
    search() {
      this.filter.page = 1;
      this.filter.id = this.id;

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList(this.currentPage);
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.segmentActionUsers(this.id, params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.items = response.data.data.data;
          this.paginate.total = response.data.data.total;
          this.paginate.totalPage = response.data.data.last_page;
          this.paginate.currentPage = response.data.data.current_page;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    createLinkPage(pageNum) {
      return `?id=${this.id}&page=${pageNum}`;
    },
    convertStatus(status) {
      let constStatus = this.status;
      let result = status;
      constStatus.forEach(function(item) {
        if (item.value === status) {
          result = item.text;
        }
      })

      return result;
    }
  },
  created() {
    this.query = this.$route.query;
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
